@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.help {
    color: white;
    padding: 80px 0 100px;

    img,
    svg,
    canvas,
    iframe,
    video {
        max-width: 100%;
    }

    a,
    span {
        display: inline-flexbox;
    }

    .logo_ img {
        background-color: #131415;
        padding: 20px 10px;
        border-radius: 10px;
        width: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        height: 200px;
    }

    @media (min-width: 1400px) {
        .logo_ h1 {
            font-size: 44px;
        }
    }

    .logo_ h1 {
        margin: 20px 0;
    }

    .container {
        max-width: 900px;
        margin: 0 auto;
    }

    img:has(+img),
    img:has(+img)+img {
        padding: 5px;
        width: 50%;
    }

    .table {
        white-space: nowrap;
        vertical-align: middle;
        --bs-table-bg: transparent;

        td,
        th {
            color: white;
            border-bottom-color: rgba(255, 255, 255, 0.2);
            font-size: 14px;
        }
    }

    img,
    .img {
        max-width: 100%;
        flex-shrink: 0;
        width: 360px;
    }

    .content {
        font-size: 14px;
        margin-bottom: 20px;
    }

    ul {
        padding-top: 10px;

        li {
            font-size: 14px;
            margin-bottom: 12px;
        }
    }

    h3 {
        margin-bottom: 10px;
    }

    .version {
        margin-bottom: 20px;
    }
}