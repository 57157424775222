$s3: "https://d31t17jekhn3g5.cloudfront.net/";

// colors
$bodyBg: #010100;
$black: black;
$white: white;
$lightBlue: #265DC7;
$orangeClr: #FF9338;
$lightText: #FDF5E9;
$darkblue: #020C3E;
$navBg: #01040F;
$levelBg: #071022;
$greenClr: #00EE4F;
$levelProgressBg: #011828;
$orangeClr2: #FE881C;
$lightText2: #EBEBF5;
$layoutBg: #05071A;
$lightBlue2: #A358FF;
$rankActive: #0A4CB1;
$avatarBg: #2F1151;
$greenClr2: #00FF7F;
$greenClr3: #01E17B;
$toastBg: #070B1E;
$dangerClr: #F04248;
$warningClr: #FFD21F;

// gradients
$gradient: linear-gradient(180deg, #010618 0%, #030325 67.68%);
$gradient2: linear-gradient(180deg, #FDE0BC 0%, #F857E1 165.4%);
$gradient3: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
$gradient4: linear-gradient(182.45deg, #FC9FDB 1.22%, #FFC765 98.78%);
$gradient5: linear-gradient(243deg, #F957EA 0%, #FEEBCA 69.67%);
$gradient6: linear-gradient(180deg, #00406D 0%, #03050E 100%);
// z indexes


// mixins
@mixin textgradient($g: $gradient2, $color: $white) {
    color: $color;
    background: $g;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

%border_box {
    background-color: rgba($white, 0.06);
    border-radius: 2rem;
    padding: 0.9rem 2rem;
    position: relative;
    border: 1px solid rgba($white, 0.1);

    &::before {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 100%;
        max-width: 23.4rem;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.5%, rgba(153, 153, 153, 0) 100%);
        opacity: 0.5;
        pointer-events: none;
    }
}