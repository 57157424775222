@import "../../Assets/_var.scss";

.custom_btn {
    // background-color: $lightBlue;
    background: linear-gradient(90deg, #D83059 0%, #EC9655 100%);
    color: $white;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 4.8rem;
    padding: 0 2.2rem;
    border-radius: 1rem;
    position: relative;
    z-index: 1;
    text-shadow: 0.77px 0px 2.3px #00000059;

    @media (max-width: 369px) {
        height: 4.4rem;
        font-size: 1.4rem;
    }

    .dots_loader {
        height: auto;
        min-height: auto;

        .dot {
            background-color: rgba($white, 0.8);
        }
    }

    &:disabled {
        background-image: url($s3 + "disable-btn.png");
        background-color: $black;
    }

    &.green_btn {
        background-image: url($s3 + "green-btn.png");
    }

    &.border_btn {
        background: transparent;
        border-bottom: 1px solid rgba($white, 0.3);
    }

    &.width {
        width: 50%;
    }
}