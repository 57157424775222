.header {
    /* background-color: #110028;  */
    padding: 20px;
    /* margin-top: 15px; */
    /* display: flex;
    justify-content: left;
    align-items: center; */
    position: static;
    z-index: 999;
    width: 100%;
    backdrop-filter: blur(8px);
    margin-bottom: 20px;

    /* border-bottom: solid 2px #3a2067 ; */
}

@media (max-width: 575px) {
    .header {
        padding: 10px;
        margin-bottom: 0;
    }
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 120px;
}

@media (max-width: 575px) {
    .logo {
        height: 60px;
    }
}

.title {
    color: white;
    font-size: 24px;
    font-family: Arial, sans-serif;
    white-space: nowrap;
    /* Adjust the font-family as needed */
}