@import "../../Assets/_var.scss";

.dots_loader {
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .dot {
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background-color: rgba($white, 0.5);
        border-radius: 50%;
        animation: bounce 1.5s infinite;
        -webkit-animation: bounce 1.5s infinite;
    }

    .dot:nth-child(1) {
        animation-delay: 0s;
    }

    .dot:nth-child(2) {
        animation-delay: 0.3s;
    }

    .dot:nth-child(3) {
        animation-delay: 0.6s;
    }

    @keyframes bounce {

        0%,
        80%,
        100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-10px);
        }
    }
}