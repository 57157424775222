
.dots_loader {
    min-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dots_loader .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white; /* This line will not work in pure CSS since $white is a SCSS variable */
    border-radius: 50%;
    animation: bounce 1.5s infinite;
    -webkit-animation: bounce 1.5s infinite;
}

.dots_loader .dot:nth-child(1) {
    animation-delay: 0s;
}

.dots_loader .dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dots_loader .dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }
}
